import React from 'react'
import styles from "./Form.module.css"
import img from "../Img/vitrine.webp"

export default function Form() {

  const handleSubmit = (e) => {
  }

  return (
    <div className={styles.conteiner} id="contact">
        <div className={styles.boxConteiner}>
            <div className={styles.imgConteiner}>
                <img src={img} />
            </div>
            <div className={styles.formConteiner}>
              <form onSubmit={handleSubmit}>
                <h1>ENTRE EM CONTATO</h1>
                <p>Enviaremos um especialista que fará uma <span>AVALIAÇÃO GRATUITA</span> e indicará o melhor sistema de segurança para você.</p>
                <input type='text' placeholder='Nome'/>
                <input type='tell' placeholder='Telefone'/>
                <select name="select">
                  <option value="valor1" disabled={true} selected>Serviços de interesse*</option>
                  <option value="valor2">Alarme monitorado</option>
                  <option value="valor3" >Alarme monitorado com câmeras</option>
                  <option value="valor4">Somente câmeras</option>
                </select>
                <textarea type='text' placeholder='Qual serviço deseja contratar?' className={styles.textAreaInput}></textarea>
                <button>Enviar</button>
              </form>
            </div>
        </div>
    </div>
  )
}
