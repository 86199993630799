import styles from "./Introduction.module.css";
import circuito from "../Img/circuito.png"

const Introduction = () => {

  const handleScrollAbout = (event) => {
    event.preventDefault();
    const element = document.querySelector("#about");
    element.scrollIntoView({ behavior: "smooth" });
  }

  return (
    <div className={styles.conteiner}>
        <div className={styles.infsConteiner}>
            
            <h1 className="animation">SEGURANÇA É COISA SÉRIA!</h1>
            <button onClick={handleScrollAbout}>Sobre</button>
        </div>
    </div>
  )
}

export default Introduction