import { useState } from "react";
import styles from "./Project.module.css";

import { useNavigate } from "react-router-dom";


const Project = ({ data }) => {


    return (
        <div className={styles.conteiner}>
            <div className={styles.imgConteiner}>
                <img src={data.image} />
            </div>
            <div className={styles.textConteiner}>
                <h1>{data.tittle}</h1>
                <p>{data.description}</p>
            </div>
        </div>
    )
}

export default Project