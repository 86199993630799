import './App.css';

//Components
import Home from './pages/Home';

//Router
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import NavBar from './Components/NavBar';
import Footer from './Components/Footer';

//pages
import { useEffect, useState } from 'react';

function App() {

  useEffect(() => {
        
    window.scrollTo(0,0);

}, [])

  return (
    <div className="App">
       <BrowserRouter>
          <NavBar/>
          <div className='conteiner'>
          <Routes>
            <Route path='' element={<Home/>}/>
          </Routes>
          </div>
          <Footer/>
       </BrowserRouter>
    </div>
  );
}

export default App;
