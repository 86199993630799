import styles from "./Footer.module.css";

const Footer = () => {
  return (
    <footer className={styles.footer}>
        <div className={styles.webmemes}>
            <h3>Desenvolvido por ADT</h3>
            <p>ADT Agente Autorizado Campinas/RMC &copy; 2024</p>
        </div>
        <div className={styles.webmemes}>
            <h3>Contato</h3>
            <p>Email: contato.adt@autorizadarmc.com.br</p>
            <p>WhatsApp: +55 (19) 997253449</p>
            <p>Instagram: <a href="https://www.instagram.com/autorizada.rmc/">autorizada.rmc</a></p>
        </div>
        <div className={styles.webmemes}>
          <h3>Sobre</h3>
          <p>Presente há 20 anos no Brasil e reconhecida mundialmente, a ADT é pioneira em alarme monitorado e quer garantir a sua tranquilidade, tanto dentro quanto fora de casa. Por isso, está sempre se atualizando e adicionando serviços para te manter protegido.</p>
        </div>
    </footer>
  )
}

export default Footer