//Components
import About from '../../Components/About';
import Form from '../../Components/Form';
import Introduction from '../../Components/Introduction';
import Projects from '../../Components/Projects';
import styles from "./style.module.css"


function Home() {
  return (
    <div styles={styles.conteiner}>

      <Introduction/>
      <About/>
      <Projects/>
      <Form/>

    </div>
  );
}

export default Home;