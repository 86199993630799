import styles from "./About.module.css";
import aboutImg from "../Img/aboutImg.jpeg"
import { useRef } from "react";

const About = () => {

  const handleScrollProjects = (event) => {
    event.preventDefault();
    const element = document.querySelector("#projects");
    element.scrollIntoView({ behavior: "smooth" });
  }

  const navigateToTkx = () => {

    const element = document.querySelector("#contact");
    element.scrollIntoView({ behavior: "smooth" });
  }  

  return (
    <div className={styles.conteiner} id="about">
        <div className={styles.myPhotoConteiner}>
          <div>
            <img src={aboutImg}/>
          </div>
        </div>
        <div className={styles.aboutConteiner}>
            <h1>Sobre</h1>  
            <h2>PROTEJA O QUE É IMPORTANTE PARA VOCÊ. CONFIE NA ADT - A Nº 1 EM <span>ALARME MONITORADO E CÂMERAS</span>.</h2>
            <p>Presente há 20 anos no Brasil e reconhecida mundialmente, a ADT é pioneira em alarme monitorado e quer garantir a sua tranquilidade, tanto dentro quanto fora de casa. Por isso, está sempre se atualizando e adicionando serviços para te manter protegido.</p>
            <div>
            <button onClick={handleScrollProjects}>Serviços</button>
            <button onClick={navigateToTkx}>Contato</button>
            </div>
        </div>
    </div>
  )
}

export default About