import styles from "./NavBar.module.css";
import logo from "../Img/logo.png";
import Contact from "./Contact";
import { useState } from "react";
import { VscThreeBars } from 'react-icons/vsc';
import ResponsiveNavBar from "./ResponsiveNavBar";

const NavBar = () => {

  const [state, setState] = useState(false);
  const [responsive, setResponsive] = useState(false);

  const handleResponsive = () => {
    setResponsive(!responsive);
  }

  const handleState = () => {
    setState(!state);
  }

  const cancelElement = () => {
    setState(false);
  }

  const handleScrollAbout = (event) => {
    event.preventDefault();
    const element = document.querySelector("#about");
    if(element) {
      element.scrollIntoView({ behavior: "smooth" });
    } else {
      return
    }
  }

  const handleScrollProjects = (event) => {
    event.preventDefault();
    const element = document.querySelector("#projects");
    if(element) {
      element.scrollIntoView({ behavior: "smooth" });
    } else {
      return
    }
  }

  const handleScrollContact = (event) => {
    event.preventDefault();
    const element = document.querySelector("#contact");
    if(element) {
      element.scrollIntoView({ behavior: "smooth" });
    } else {
      return
    }
  }

  return (
    <div className={styles.fatherConteiner}>
      <div className={styles.conteiner}>
        <div className={styles.logoConteiner}>
          <img src={logo} />
        </div>
        <div className={styles.refsConteiner}>
          <span onClick={handleScrollAbout}>Sobre</span>
          <span onClick={handleScrollProjects}>Serviços</span>
          <span onClick={handleScrollContact}>Contato</span>
        </div>
        <div className={styles.responsiveRefs}>
          <span onClick={handleResponsive}><VscThreeBars/></span>
        </div>
        {state && (
          <div className={styles.conteinerContact}>
            <Contact prop={cancelElement} />
          </div>
        )}
      </div>
      <div>
        {responsive && (
          <div>
            <ResponsiveNavBar/>
          </div>
        )}
      </div>
    </div>
  )
}

export default NavBar